exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-aoi-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/aoi/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-aoi-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-ciu-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/ciu/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-ciu-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-consulting-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/consulting/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-consulting-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-corporate-it-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/corporate-it/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-corporate-it-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-data-tech-office-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/data-tech-office/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-data-tech-office-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-dpu-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/dpu/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-dpu-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-dso-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/dso/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-dso-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-hr-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/hr/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-hr-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-id-governance-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/id-governance/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-id-governance-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-misc-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/misc/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-misc-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-nic-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/nic/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-nic-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-payment-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/payment/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-payment-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-pr-factory-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/pr-factory/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-pr-factory-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-purchase-negotiation-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/purchase-negotiation/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-purchase-negotiation-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-ssg-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/ssg/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-ssg-index-mdx" */),
  "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-system-general-affairs-index-mdx": () => import("./../../../src/pages/team/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/a13209/workspace/git-saiyou/src/team/system-general-affairs/index.mdx" /* webpackChunkName: "component---src-pages-team-mdx-frontmatter-slug-js-content-file-path-src-team-system-general-affairs-index-mdx" */)
}

